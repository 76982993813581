.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 0 36px;
}
.navbar img {
  width: 17%;
}



.navLinks a {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  color: #303133;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 1.2px;
  transition: 0.2s;
}

.navLinks a:hover {
  color: #30313380;
}

.navLinks {
  display: flex;
  gap: 20px;
}

.mobileShow {
  display: none;
}
.mobileHide {
  display: block;
}

@media screen and (max-width: 600px) {
  .mobileShow {
    display: block;
    margin: auto;
    width: 90%;
    margin-top: 30px;
  }
  .mobileHide {
    display: none !important;
  }
}